.left{
    margin-top: 100;
    top: 12px;
    left: 0.0002117156982421875px;
left: 881px;
width: 862px;
height: 1056px;
opacity: 1;
/* left: 1762px; */
opacity: 1;
}

.block{
    top: 334px;
    left: 722px;
    width: 476px;
    height: 250px;
    background: #1D1C1C 0% 0% no-repeat padding-box;
    /* border-radius: 10px; */
    opacity: 1;
}
.text{
    margin-left: 25px;
padding-top: 20px;
 top: 384px;
   background: #1D1C1C 0% 0% no-repeat padding-box;
left: 803px;
width: 314px;
height: 66px;
color: #ffffff;
text-align: center;
font: normal normal medium 26px/34px PFDinMono ☞;
letter-spacing: 0.01px;
color: #FFFFFF;
text-transform: uppercase;
opacity: 1;
}
.input{
    top: 480px;
left: 812px;
width: 296px;
height: 50px;
opacity: 1;
color: #FFFFFF;
}
.label{
    /* top: 480px; */
/* left: 812px;
width: 81px;
height: 19px;
color: var(--unnamed-color-ffffff);
text-align: left;
font: normal normal normal 16px/24px PFDinMono-Regular ☞;
letter-spacing: 0.26px; */
color: #FFFFFF;
margin-left: 82px;
margin-top: 10px;
/* opacity: 0.4; */
}
.input_field{
margin-left: 82px;
margin-top: 0px;
top: 504px;
left: 812px;
width: 100px;
height: 0px;
border: 2px solid #ffffff;
color: white;
opacity: 0.7;
}