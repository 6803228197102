/*--------------------------------------------------------------------------------------*/
/*Button Animation
/*--------------------------------------------------------------------------------------*/

.draw-border{--uiButtonTextColor: var(--buttonTextColor); --uiButtonBorderWidth: var(--buttonBorderWidth, 4px);--uiButtonBorderColor: var(--buttonBorderColor); --uiButtonHoverTextColor: var(--buttonHoverTextColor); --uiButtonHoverBorderColor: var(--buttonHoverBorderColor); box-shadow: inset 0 0 0 var(--uiButtonBorderWidth) var(--uiButtonBorderColor); color: var(--uiButtonTextColor); transition: color .25s .08333333s; position: relative;}
.draw-border::before,.draw-border::after{content: ''; box-sizing: border-box;  width: 0; height: 0; border: 0 solid var(--uiButtonBorderColor); pointer-events: none; transition-property: border-color, width, height; transition-duration: .25s; /* .25s for border-color, .25s for width, .25s for height */ position: absolute; bottom: 0; right: 0;}
.draw-border::before{border-bottom-width: var(--uiButtonBorderWidth); border-left-width: var(--uiButtonBorderWidth);/* A bottom border of button have to be hidden through 0.25s so I use the 0.25s value as the second argument */ transition-delay: .35s, .25s, 0s; /* .35s for border-color, .25s for width, 0s for height */}
.draw-border::after{border-top-width: var(--uiButtonBorderWidth); border-right-width: var(--uiButtonBorderWidth); /* A right border of button have to be hidden through 0.25s so I use the 0.25s value as the third argument */ transition-delay: .35s, 0s, .25s; /* .35s for border-color, 0s for width, .25s for height */}
.draw-border:hover{color: var(--uiButtonHoverTextColor);}
.draw-border:hover::before, .draw-border:hover::after{width: 100%; height: 100%; border-color: var(--uiButtonHoverBorderColor);/* Changing border color don't have to be smooth so I use the 0s value as the first argument */ transition-duration: 0s, .25s, .25s; /* 0s for border-color, .25s for width, .25s for height */}
.draw-border:hover::before{/* A left border of button have to be showed through 0.25s so I use the 0.25s value as the third argument */transition-delay: 0s, 0s, .25s; /* 0s for border-color, 0s for width, .25s for height */}
.draw-border:hover::after{/* A top border of button have to be showed through 0.25s so I use the 0.25s value as the second argument */transition-delay: 0s, .25s, 0s; /* 0s for border-color, .25s for width, 0s for height */}
/*
=======
SETTINGS
=======
*/

body {--buttonTextColor: #fff; --buttonBorderColor: #fff; --buttonBorderWidth: 2px; --buttonHoverTextColor: #FFFB00; --buttonHoverBorderColor: #FFFB00;}

/*--------------------------------------------------------------------------------------*/
/*Line Left Right Animation
/*--------------------------------------------------------------------------------------*/

@keyframes moveImage {
    100% {
      transform: translateX(calc(100vw));
    }
}
@keyframes moveImage2 {
    100% {
      transform: translateX(calc(-100vw));
    }
}
















