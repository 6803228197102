@media(max-width:1200px) {

    .scroll-canvas {
        overflow: auto;
        margin: 0 30px;
        position: relative;
    }
}

@media only screen and (min-width:1200px) and (max-width:1600px) {

    .game-over-screen-bg .game-form.col-md-4.m-auto.text-center {
        flex: 0 0 80%;
        max-width: 80%;
    }

    .game-form.col-md-4.m-auto.text-center {
        max-width: 60%;
    }

    .modal-dialog.modal-xl.modal-dialog-centered {
        max-width: 70%;
    }

    .play-video-box {
        width: 430px;
    }




}

@media only screen and (min-width:1200px) and (max-width:1600px) {

    .leader-bored .container {
        max-width: 97%;
    }

    .leader-bored-box-R-head h3 {
        font-size: 20px;
    }

    .leader-bored-box-R-head span {
        font-size: 16px;
    }


}

@media only screen and (min-width:992px) and (max-width:1400px) {

    .leader-bored-info li {
        font-size: 14px;
    }

    .leader-bored-box .leader-bored-info li {
        padding-right: 12px;
    }
}


@media only screen and (min-width:992px) and (max-width:1500px) {

    .leader-bored-bg {
        padding: 50px 40px 75px;
    }




}

/* 
.width-fix-body.canvas-body {max-width:1560px; margin: auto;}
.puzzle-image-container,.konvajs-content{max-width: 1500px !important;} */

@media only screen and (max-width:1600px) {

    .side-bar-L {
        width: 190px;
    }

    .game-side-R {
        padding: 0 0 0 190px;
    }

    ul.sidebar-link-list li a {
        padding: 4px 22px;
    }

    .side-bar-L button.styledBtn.hint-btn {
        padding: 7px 8px;
        font-size: 16px;
    }
}

@media only screen and (max-width:1340px) {}

@media only screen and (max-width:1199px) {




    .leader-bored-bg .col-md-6 {
        max-width: 100%;
        flex: 0 0 100%;
    }
}













@media (max-width:1199px) {
    .form-bg-inner {
        width: 75%;
        margin: auto;
    }

    .game-form.col-md-4 {
        flex: 0 0 60%;
        max-width: 60%
    }

    /* / .width-fix-body {
        max-width: 85%;
        margin: auto
    } */

    /* / .width-fix-body {
        width: 100%;
        height: auto;
        margin: auto;
    } */

    .game-over-screen-bg .form-bg-inner {
        width: 75%;
        margin: auto;
    }

    .game-form.col-md-4 {
        flex: 0 0 80%;
        max-width: 80%;
    }


    /* ul.leader-bored-info,
    .leader-bored-box-R-head {
        flex-wrap: wrap;
    } */

    .leader-bored-info {
        flex-wrap: wrap;
    }
}



@media (max-width:991px) {
    .form-bg-inner {
        width: 85%
    }

    .side-bar-L {
        width: 220px
    }

    .game-side-R {
        padding: 0 0 0 220px
    }

    .game-form.col-md-4 {
        flex: 0 0 80%;
        max-width: 80%
    }

    .sidebar-head b {
        font-size: 36px
    }

    .game-side-head h3 {
        font-size: 32px
    }

    .game-over-screen-img img {
        height: auto;
        width: 100%;
        object-fit: contain;
    }


    .leader-bored-bg {
        padding: 50px 40px 75px;
    }




}

@media (max-width:767px) {

    .form-bg:after,
    .form-bg:before {
        animation: none;
        width: 100%
    }

    .logo {
        text-align: center;
        width: 70%;
        margin: auto
    }

    .form-box {
        margin-top: 30px
    }

    .form-box {
        width: 100%
    }

    .form-head h1 {
        font-size: 24px
    }

    .form-box {
        padding: 40px 20px
    }

    .play-video-box {
        width: 100%;
        margin: initial
    }

    img.logo-screen {
        margin-bottom: 20px
    }

    .side-bar-L {
        position: static;
        width: 100%;
        background: 0 0
    }

    .sidebar-inner-title {
        padding: 0
    }

    .sidebar-body {
        padding-top: 0
    }

    ul.sidebar-link-list {
        display: flex;
        white-space: nowrap;
        margin-bottom: 0;
        height: auto !important;
        border-bottom: 3px solid #fffb00;
        overflow: initial;
    }

    .side-bar-L {
        border-bottom: 0 solid #fffb00
    }

    .game-side-R {
        padding: 0 15px
    }

    .Chapter-similer-head h3 {
        font-size: 22px
    }

    .sidebar-link-list-main {
        overflow: auto;
    }

    .game-over-screen-content h2 {
        font-size: 36px;
    }

    .game-over-screen-content p {
        font-size: 16px;
        line-height: 23px;
    }

    .leader-bored-box .leader-bored-info {
        flex-wrap: wrap;
    }
}

@media (max-width:575px) {
    .teammatesJoining h1 {
        font-size: 18px
    }

    .common-modal .modal-content {
        width: 90%;
        margin: auto
    }

    .form-head p {
        font-size: 16px
    }

    .Chapter-similer-head h3 {
        font-size: 16px;
        margin-bottom: 0;
        padding: 10px 10px !important
    }

    .button-aside.close {
        opacity: 1
    }

    .game-over-screen-content h2 {
        font-size: 28px;
    }

    .game-over-screen-bg .form-bg-inner {
        width: 100%;
        margin: auto;
    }

    .game-side-R.shrink-body {
        padding-right: 0px;
    }

    .leader-bored-box-R-head {
        flex-wrap: wrap;
    }

    .leader-bored-box-R-head span {
        width: 100%;
    }
}

@media (max-width:480px) {
    img.logo-screen {
        width: 80%
    }

    .play-head p {
        font-size: 14px
    }

    .yallow-content {
        font-size: 14px
    }

    .teammatesJoining h1 {
        font-size: 14px
    }

    /* / .width-fix-body {
        max-width: 90%;
        margin: auto
    } */

    /* / .width-fix {
        max-width: 100%
    } */

    .Chapter-similer-head p {
        font-size: 16px
    }

    .game-form.col-md-4 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .similer-title {
        font-size: 20px
    }

    .sidebar-head b {
        font-size: 30px;
    }

    .sidebar-head span {
        font-size: 16px;
    }

    .audio-player {
        width: 90%;
        margin: auto;
    }

    .game-over-screen-content h2 {
        font-size: 26px;
    }

    .modal-body {
        padding: 0 20px !important;
    }
}