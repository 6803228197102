/*--------------------------------------------------------------------------------------*/
/*header-css-here
/*--------------------------------------------------------------------------------------*/


@font-face {
  font-family: 'din_mediumregular';
  src: url('../fonts/din_medium-webfont.woff2') format('woff2'),
    url('../fonts/din_medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'pfdinregular';
  src: url('../fonts/pf_din_mono_light-webfont.woff2') format('woff2'),
    url('../fonts/pf_din_mono_light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'PFDinMonoMedium';
  src: url('../fonts/PFDinMonoMedium.eot');
  src: url('../fonts/PFDinMonoMedium.eot') format('embedded-opentype'),
    url('../fonts/PFDinMonoMedium.woff2') format('woff2'),
    url('../fonts/PFDinMonoMedium.woff') format('woff'),
    url('../fonts/PFDinMonoMedium.ttf') format('truetype'),
    url('../fonts/PFDinMonoMedium.svg#PFDinMonoMedium') format('svg');
}

@font-face {
  font-family: 'PFDinMonoRegular';
  src: url('../fonts/PFDinMonoRegular.eot');
  src: url('../fonts/PFDinMonoRegular.eot') format('embedded-opentype'),
    url('../fonts/PFDinMonoRegular.woff2') format('woff2'),
    url('../fonts/PFDinMonoRegular.woff') format('woff'),
    url('../fonts/PFDinMonoRegular.ttf') format('truetype'),
    url('../fonts/PFDinMonoRegular.svg#PFDinMonoRegular') format('svg');
}

@font-face {
  font-family: 'PFDinMonoBold';
  src: url('../fonts/PFDinMonoBold.eot');
  src: url('../fonts/PFDinMonoBold.eot') format('embedded-opentype'),
    url('../fonts/PFDinMonoBold.woff2') format('woff2'),
    url('../fonts/PFDinMonoBold.woff') format('woff'),
    url('../fonts/PFDinMonoBold.ttf') format('truetype'),
    url('../fonts/PFDinMonoBold.svg#PFDinMonoBold') format('svg');
}


@font-face {
  font-family: 'proxima_novalight';
  src: url('../fonts/fontsfree-net-proxima-nova-light-webfont.woff2') format('woff2'),
    url('../fonts/fontsfree-net-proxima-nova-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}



@font-face {
  font-family: 'proxima_novaregular';
  src: url('../fonts/proxima_nova_font.woff2') format('woff2'),
    url('../fonts/proxima_nova_font.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'proxima_novasemibold';
  src: url('../fonts/fontsfree-net-proxima-nova-sbold-webfont.woff2') format('woff2'),
    url('../fonts/fontsfree-net-proxima-nova-sbold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'proxima_novabold';
  src: url('../fonts/fontsfree-net-fontsfree-net-proxima-nova-bold.woff2') format('woff2'),
    url('../fonts/fontsfree-net-fontsfree-net-proxima-nova-bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'proxima_novablack';
  src: url('../fonts/fontsfree-net-proxima-nova-black.woff2') format('woff2'),
    url('../fonts/fontsfree-net-proxima-nova-black.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

/*--------------------------------------------------------------------------------------*/
/*General-Css-Here
/*--------------------------------------------------------------------------------------*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* ::-ms-reveal {
  display: none;
} */

button:focus {
  border: 0;
  outline: 0
}

a {
  -webkit-transition: .5s ease-out;
  -moz-transition: .5s ease-out;
  -o-transition: .5s ease-out;
  transition: .5s ease-out;
  cursor: pointer;
}

body {
  box-sizing: border-box;
  background: #fff;
  font-size: 14px;
  font-family: 'PFDinMonoRegular';
}

ul {
  margin: 0;
  padding: 0;
  list-style: none
}

img {
  max-width: 100%;
}

header,
footer,
section,
.site-content {
  display: block;
}

.site-content:after,
.site-content:before {
  display: table;
  clear: both;
  content: ""
}

header:after,
header:before,
footer:after,
footer:before,
section:after,
section:before {
  display: table;
  clear: both;
  content: ""
}

.container:after,
.container:before {
  display: table;
  clear: both;
  content: "";
}

a {
  outline: none
}

figure {
  margin-bottom: 0;
}

p,
span,
small,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0
}

a.navbar-brand {
  padding: 0
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.btn {
  font-size: 16px;
  color: #fff;
  background: #FF2626;
  border-radius: 5px;
  height: 45px;
  padding: 0px 25px;
  font-family: 'GeneralSans-Semibold';
}

.btn:hover {
  color: #fff;
  background: #A11313;
}

.nav-link:focus,
.nav-link:hover {
  color: #fff;
}

button.styledBtn {
  color: #000;
  text-align: center;
  border: 0;
  font-size: 18px;
  text-transform: uppercase;
  font-family: "PFDinMonoRegular";
  margin: 10px auto auto;
  background: #fffb00 0 0 no-repeat padding-box;
  box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 20%);
  letter-spacing: 2px;
  height: 43px;
  line-height: 44px;
  display: flex;
  padding: 0px 50px;
  opacity: 1;
  justify-content: center;
  align-items: center;
}

button.styledBtn-disabled {
  background: #d3d3d3 0 0 no-repeat padding-box !important;
  cursor: not-allowed !important;
}

button.styledBtn span{position: relative; top: 1px;}
.edge-class button.styledBtn span{top:0;}





/* button.styledBtn {
  color: #000;
  text-align: center;
  border: 0;
  font-size: 18px;
  text-transform: uppercase;
  font-family: "PFDinMonoRegular";
  margin: 10px auto auto;
  background: #fffb00 0 0 no-repeat padding-box;
  box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 20%);
  letter-spacing: 2px;
  height: 44px;
  line-height: 44px;
  display: flex;
  padding: 11px 50px 8px 50px !important;
  opacity: 1;
  justify-content: center;
  align-items: center;
}

@supports (-ms-ime-align:auto) {
  button.styledBtn {
    color: #000;
    text-align: center;
    border: 0;
    font-size: 18px;
    text-transform: uppercase;
    font-family: "PFDinMonoRegular";
    margin: 10px auto auto;
    background: #fffb00 0 0 no-repeat padding-box;
    box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 20%);
    letter-spacing: 2px;
    height:auto;
    line-height:normal;
    display: flex;
    padding: 12px 50px 12px 50px !important;
    opacity: 1;
    justify-content: center;
    align-items: center;
  }
} */





button.styledBtn span{
  /* display: block; */
  /* margin-top: 3.585px */
}

.styledBtn:active {
  box-shadow: 0 1px #666;
  /* transform: translateY(4px); */
  transform: none;
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1400px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


/*--------------------------------------------------------------------------------------*/
/*login-page-css-here
/*--------------------------------------------------------------------------------------*/

.form-bg {
  background: url(../images/user/1.svg) repeat #000000;
  background-size: cover;
  width: 100%;
  position: relative;
}

.form-bg:before {
  content: "";
  width: 454px;
  height: 15px;
  background: #FFFB00;
  position: fixed;
  right: 0;
  top: 0;
}

.form-bg:after {
  content: "";
  width: 454px;
  height: 15px;
  background: #FFFB00;
  position: fixed;
  left: 0;
  bottom: 0;
}

.form-box {
  width: 476px;
  background: #1D1C1C;
  padding: 30px;
  margin-top: 50px;
}

.form-head {
  margin-bottom: 35px;
}

.form-head h1 {
  font-size: 25px;
  color: #FFFFFF;
  font-family: 'PFDinMonoMedium';
  text-align: center;
  text-transform: uppercase;
  line-height: 35px;
  letter-spacing: 1px;
}

.form-control,
.form-control:focus {
  background: none;
  border: none;
  border-bottom: solid 2px #FFFFFF;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  outline: none;
  box-shadow: none;
  font-size: 16px;
  color: #FFFFFF;
  font-family: 'PFDinMonoMedium';
}

.form-label {
  font-size: 14px;
  text-transform: uppercase;
  color: #777676;
  margin-bottom: 0;
  margin-top: 10px;
}

.form-submit-btn {
  margin: 40px auto auto auto;
  display: flex;
  align-items: center;
  background: transparent;
  border: solid 1px #fff;
  padding: 12px 20px;
}

.form-submit-btn span {
  font-size: 20px;
  text-transform: uppercase;
  font-family: 'PFDinMonoMedium';
  padding-right: 30px;
}

.form-submit-btn i {
  font-size: 22px;
}

.teammatesJoining {
  bottom: 0;
  left: 10px;
  display: flex;
  position: static;
  margin-top: 15px;
}

.teammatesJoining h1 {
  font-size: 22px;
  color: #FFFFFF;
  text-align: left;
  line-height: 50px;
  padding-left: 8px;
  letter-spacing: 2px;
}

.teammatesJoining h1 span {
  text-transform: capitalize;
}

img.logo-screen {
  margin-top: 15px;
}

.sub-content.team-name {
  color: #FFFB00;
  padding-top: 20px;
  font-size: 22px;
  font-family: PFDinMonoRegular;
  letter-spacing: 2px;
}

/*--------------------------------------------------------------------------------------*/
/*tutorials-css-here
/*--------------------------------------------------------------------------------------*/

.play-head {
  padding: 15px 10px 10px
}

.play-video-box {
  width: 540px;
  background: #1d1c1c;
  border-radius: 0px;
  margin-top: 25px
}

.play-footer {
  padding: 15px 20px 25px
}

.play-head h3 {
  color: #fff;
  font-size: 23px;
  text-transform: uppercase;
  font-family: 'PFDinMonoMedium';
  letter-spacing: 1px;
  margin-top: 8px
}

.play-head p {
  color: rgb(255 255 255 / 70%);
  font-size: 16px;
  font-family: 'Raleway', sans-serif;
  line-height: 1.3;
}

.play-footer p {
  color: rgb(255 255 255 / 70%);
  text-align: justify;
  font-size: 16px;
  font-family: 'proxima_novalight'
}

.play-footer .form-submit-btn i {
  font-size: 18px;
  color: #02dc81
}

.play-footer .form-submit-btn {
  margin: 10px auto auto auto
}

.play-body {
  position: relative
}

.play-icon {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  margin: auto;
}

.play-video-modal .modal-dialog {
  max-width: 90%;
  margin: 1.75rem auto
}

.play-video-modal .modal-body {
  padding: 0;
}

.play-video-modal .modal-head {
  background: #1d1c1c;
  text-align: right;
  padding: 18px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end
}

.play-video-modal button.close span {
  background: transparent;
  padding-right: 15px;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'PFDinMonoRegular'
}

.play-video-modal button.close {
  background: transparent;
}

.play-video-modal button.close span img {
  margin-right: 10px;
}

.play-video-modal .modal-content {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  background: transparent;
  border: 0
}

/*--------------------------------------------------------------------------------------*/
/*game-screen-css-here
/*--------------------------------------------------------------------------------------*/


.side-bar-L {
  width: 220px;
  background: #1d1c1c;
  position: fixed;
  top: 0;
  bottom: 0;
  transition: all .3s;
  border-bottom: 15px solid #fffb00
}

.game-side-R {
  height: 100%;
  min-height: 100vh;
  transition: all .3s;
  background: #000;
  padding: 0 0 0 220px
}

.sidebar-head {
  text-align: center;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  background: #000;
  /* border-right:1px solid rgb(255 255 255 / 40%) */
}

.sidebar-head b {
  font-size: 36px;
  font-family: 'PFDinMonoRegular';
  line-height: normal;
  padding-bottom: 5px
}

.sidebar-head small {
  font-size: 15px;
  font-family: 'PFDinMonoMedium';
  padding-bottom: 12px
}

.sidebar-head span {
  font-size: 12px;
  font-family: 'PFDinMonoRegular'
}

ul.sidebar-link-list li a {
  padding: 4px 30px;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: rgb(255 255 255 / 40%) !important;
  font-family: 'PFDinMonoRegular';
  cursor: default;
  /* justify-content: center !important; */
}

.sidebar-inner-title {
  padding: 10px 30px 20px;
  font-size: 26px;
  color: #fff;
  font-family: 'PFDinMonoMedium'
}

.sidebar-link-list {
  max-height: 100%;
  height: calc(100vh - 225px) !important;
  overflow: auto
}

.sidebar-body {
  padding-top: 20px;
  color: "#FFF"
}

.sidebar-link-list li a span {
  padding-left: 25px;
  /* margin-bottom: 3.5px; */
}

.sidebar-link-list li a span {
  padding-left: 25px;
}
.sidebar-link-list li a span img{
  margin-bottom: 5.2px;
}

.sidebar-link-list li.active a {
  background: #fffb00;
  color: #000 !important
}


.game-side-head h3 {
  color: #fff;
  font-size: 32px;
  font-family: 'PFDinMonoMedium';
}

.game-side-head p {
  font-size: 18px;
  color: rgb(255 255 255 / 70%);
  font-family: 'proxima_novalight';
  line-height: 26px
}

.game-side-head {
  margin: 0 5px;
  padding: 0px 0 5px
}

.game-side-body {
  padding: 20px 0 30px
}

.width-fix {
  max-width: 59%;
  margin: auto
}


.Chapter-similer-head p.title {
  background: #fffb00;
  display: inline-block;
  padding: 4px 5px 0px;
  font-size: 24px;
  text-transform: uppercase;
  font-family: 'PFDinMonoMedium';
  color: #000;
  margin-bottom: 10px;
  letter-spacing: 1px;
  word-spacing: -5px;
}

.Chapter-similer-head p {
  font-size: 16px;
  color: rgb(255 255 255 / 70%);
  font-family: 'Raleway', sans-serif;
  line-height: 1.3;
  letter-spacing: 2px;
}

.Chapter-similer-head {
  margin-bottom: 35px;
  padding-left: 30px;
  padding-right: 30px;
}

.banner-center-img img {
  width: 100%;
  height: 481px;
  border-radius: 10px
}

.similer-title {
  font-size: 23px;
  text-transform: uppercase;
  color: #fff;
  font-family: 'PFDinMonoMedium';
  margin-bottom: 20px;
  letter-spacing: 1px;
}

.game-form .form-group {
  display: flex;
  align-items: center
}

.submit-btn {
  background: transparent;
  padding: 3px 7px;
  border-radius: 2px;
  border: 1px #FFF solid;
  display: flex;
  text-transform: uppercase;
  align-items: flex-start;
  margin-left: 15px;
  margin-bottom: 25px;
  color: #FFFFFF;
  font-family: 'PFDinMonoMedium';
  font-size: 14px
}

.submit-btn i {
  color: #02dc81;
  font-size: 14px
}

.need-hunt {
  font-size: 20px;
  border: 1px solid #fff;
  padding: 11px 15px;
  display: inline-block;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  font-family: 'PFDinMonoMedium'
}


.button-aside .toggle-btn {
  /* position: absolute;
  top: 0;
  margin: auto;
  bottom: 0;
  left: -50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  max-height: 20px;
  justify-content: center;
  letter-spacing: 2px;
  flex-direction: column; */


  /* position: absolute;
  top: 0;
  margin: auto;
  bottom: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 2px;
  flex-direction: column;
  left: -50px;
  transform: translate(0, -175px);
  height: 175px;
  border-radius: 42px 0 0 42px; */

  position: absolute;
  cursor: pointer;
  letter-spacing: 2px;
  left: -50px;
  height: 175px;
  border-radius: 42px 0 0 42px;
  top: 50%;
  transform: translate(0px, -50%);
}

.clues-side-bar {
  max-height: 100vh;
  overflow: auto;

}

.clues-side-bar h3 {
  text-align: center;
  color: #fff;
  font-size: 26px;
  text-transform: uppercase;
  font-family: 'PFDinMonoMedium';
  font-weight: normal;
}





.clues-list-main {
  height: calc(100vh - 86px);
  overflow: auto
}

.clues-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.clues-list li {
  width: 208px;
  height: 157px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  border-radius: 10px;
  margin: 6px 6px;
  flex-direction: column
}

.clues-list li span {
  background: #fffb00;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  border-radius: 33px;
  border: 2px solid #000;
  margin: 3px
}

.clues-list li .icon-box {
  margin: auto;
  display: table;
  background: #fffb00;
  border-radius: 33px;
}

.clues-list li span i {
  font-size: 24px;
}

.clues-side-bar p.clue-title {

  line-height: 1.2;
  font-size: 16px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 10px;
  margin-bottom: 0;
  /*letter-spacing: 1px;  
  font-family: 'PFDinMonoRegular';*/
  font-weight: normal;
  /*word-spacing: -5px;*/
  font-family: 'PFDinMonoMedium';
}

.button-aside {
  z-index: 9;
  width: 225px;
  height: 100vh;
  position: fixed;
  transition: .3s;
  right: -225px;
  top: 0;
  background: #1d1c1c;
  border-top: 15px solid #fffb00
}


/* .clue-viewer.modal{width: auto !important; height: auto !important; left: calc(50vw - 275px) !important; top: calc(45vh - 275px) !important;} */
.clue-viewer.modal{width: auto !important; height: auto !important;}
.clue-viewer.modal .modal-content{width: 500px;}
.pointer-none{pointer-events: none !important;}

.bg-canvas-img::selection {
  background: transparent !important;
}

.button-aside.close {
  right: 0; 
  z-index: 1073;
  opacity: 1 !important;
}

.button-aside.close:hover {
  opacity: 1 !important;
}

.toggle-btn img {
  width: 79%;
}

.msge-show-box {
  height: 481px;
  background: #1d1c1c;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 100%;
  margin: auto
}

.msge-show-box p {
  font-size: 26px;
  text-align: center;
  font-family: PFDinMonoMedium;
  padding: 40px 150px
}

.video-box-screen-1 {
  background: #dddddd0d;
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.no-clues {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff
}


.no-clues span {
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  font-family: PFDinMonoRegular;
  line-height: 1.3;
  letter-spacing: 2px;
}

.no-clues i {
  font-size: 82px;
  color: #fffb00;
  line-height: normal
}

.audio-box-screen-1 {
  height: 481px;
  background: #1d1c1c;
  display: flex;
  align-items: center;
  justify-content: center
}

.yallow-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.3;
  padding: 0 0 8px;
  font-size: 16px;
}

.yallow-color {
  color: #fffb00
}

.yallow-text i {
  font-size: 24px;
  padding-right: 12px
}

.form-head p {
  font-size: 16px;
  color: rgb(255 255 255 / 70%);
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
}

.green-arrow {
  color: #02dc81;
  margin-left: 15px;
}

.common-modal .modal-content {
  border-radius: 10px;
  background: #1d1c1c
}

.common-modal .modal-body {
  padding: 40px 25px 40px 25px
}

.common-modal .modal-title {
  font-size: 25px;
  color: #fff;
  text-transform: uppercase;
  font-family: 'PFDinMonoMedium';
  letter-spacing: 1px;
  line-height: normal;
}

.sub-content {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  padding-top: 10px;
  font-family: PFDinMonoRegular
}

.yallow-content i {
  padding-right: 10px
}

.modal-content {
  border-radius: 0px !important;
  border: none !important;
}

.congratulation h5.sub-content.text-center {
  margin-bottom: 0;
  line-height: normal;
}

.congratulation h5.yallow-content {
  padding: 0;
  margin-top: 15px;
  margin-bottom: 20px;
}




.similer-screen {
  position: relative
}

.similer-screen-head {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
  right: 20px
}

.similer-screen:after {
  background: -moz-linear-gradient(top, #000 0, rgba(0, 0, 0, .99) 1%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(top, #000 0, rgba(0, 0, 0, .99) 1%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(to bottom, #000 0, rgba(0, 0, 0, .99) 1%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  content: "";
  bottom: 0;
  height: 64px
}

.action-tool {
  display: flex
}

.action-tool li span {
  background: #fffb00;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  border-radius: 33px;
  border: 2px solid #000;
  margin: 3px
}

.action-tool li a {
  background: #fffb00;
  border-radius: 33px;
  display: flex
}

.action-tool li span i {
  font-size: 24px
}

.action-tool li {
  margin-right: 12px
}

.zoom-icon {
  color: #fff;
  font-size: 24px
}

.game-audio-box.similer-screen:after {
  display: none;
}

.no-clues b {
  height: 97px;
  width: 76px;
  background: #fffb00;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 48px;
  border: 3px solid #000
}

.no-clues i {
  font-size: 44px;
  color: #000;
  line-height: normal
}


.sidebar-scroll {
  height: calc(100vh - 54px);
  overflow: auto;
}

/* width */
.sidebar-scroll::-webkit-scrollbar,
.sidebar-link-list::-webkit-scrollbar,
.modal-dialog-scrollable::-webkit-scrollbar {
  width: 0px;
}


.similer-modal-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px 5px
}

.modal-action {
  display: flex;
  align-items: center
}

.modal-action li i {
  font-size: 26px;
  color: #fffb00;
  margin-right: 15px
}

.similer-modal-head .close-btn {
  background: 0 0
}

.close-btn span {
  color: #fff;
  font-size: 17px;
  text-transform: uppercase;
  font-family: PFDinMonoRegular;
  display: flex;
  align-items: center
}

.close-btn span img {
  margin-right: 8px
}

.clue-modal-img-box .modal-dialog {
  max-width: 677px
}

 
/* Width Change CLUE BOX */

.clue-viewer{
  transform: translate(0px, 0px);
  position: fixed;
  top: 50vh;
  left: 50vw;
  margin-top: -307.5px;
  /* margin-left: -250px; */
  opacity: 1;
}


.clue-viewer.enlrage  .modal-dialog{
  max-width: 550px !important;
 
}

.clue-viewer.enlrage .modal-content{
  width: 550px !important;
}

.clue-viewer.enlrage  .clue-modal-img-box .modal-dialog {
  max-width: 550px !important
}

.clue-viewer.enlrage .modal-content .clue-image {
  max-height: 550px !important;
}


.modal {
  background: rgb(0 0 0 / 35%)
}

.modal-backdrop.show {
  opacity: 0
}

#clue-modal-img {
  background: 0 0
}

.need-hint-content {
  background: #1D1C1C;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.need-hint-content figure span {
  background: #000;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  border-radius: 33px;
  border: 2px solid #fff;
  margin: 3px
}

.need-hint-content figure span i {
  font-size: 20px;
  color: #fff
}

.need-hint-content figure {
  background: #000;
  border-radius: 33px
}

.used-hint .need-hint-content {
  background: #02dc81
}

.need-hint-content figcaption {
  margin-left: 25px;
  flex: 1
}

.need-hint-content h3 {
  margin-bottom: 15px;
  font-weight: 700;
  color: #FFF;
  font-size: 20px;
  font-family: PFDinMonoRegular;
  letter-spacing: 2px;
}

.need-hint-content h4 {
  margin-bottom: 18px;
  color: #FFF;
  font-size: 16px;
  font-family: PFDinMonoRegular;
  letter-spacing: 0px;
}

.space-modal {
  padding: 15px
}

.unused-hint .need-hint-content {
  border: 1px solid #fff
}

.unused-hint .need-hint-content h3 {
  color: #fff
}

.unused-hint .need-hint-content h3 b {
  display: block;
  font-weight: 400;
  padding-top: 10px
}

.need-hint-content a {
  width: 100%
}

.hint-need-video {
  background: rgb(221 221 221 / 35%);
  border-radius: 0
}

.need-hint-content .hint-need-audio {
  width: 100%;
  padding: 250px;
}

.hint-need-audio .column.add-bottom {
  width: 100%
}

.hint-need-text p {
  font-size: 16px;
  color: rgb(255 255 255 / 70%);
  text-align: justify;
  font-family: proxima_novalight
}

.icon-shape span {
  background: #fffb00;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 33px;
  margin: auto;
  display: flex
}

.icon-shape b {
  background: #fffb00;
  border-radius: 33px;
  display: flex;
  margin: auto;
  width: 64px;
  height: 82px
}

.year-text {
  font-size: 22px;
  font-family: PFDinMonoRegular;
  letter-spacing: 2px;
}

.solution-description {
  font-family: PFDinMonoRegular;
  color: #FFFFFF;
  opacity: 0.7;
  margin-top: 10px;
}

button.code-submit-btn {
  flex-direction: row;
  background: #000;
  width: auto;
  padding: 1px 35px;
  text-transform: uppercase;
  justify-content: center;
  margin-left: 15px;
  margin-bottom: 15px;
  font-family: 'PFDinMonoRegular';
  font-size: 18px;
  line-height: 40px;
  position: relative;
  color: #FFF;
  letter-spacing: 2px;
  border: 1px #FFF solid;
  height: 42px;
  text-align: center;
  white-space: pre;
}

.code-submit-btn img {
  color: #02dc81;
  height: auto;
  margin-right: 10px;
}

.code-submit-btn:active {
  box-shadow: 0 1px #666;
  transform: translateY(4px);
}

/*--------------------------------------------------------------------------------------*/
/*chapter-complete-screen-css-here
/*--------------------------------------------------------------------------------------*/

/*--------------------------------------------------------------------------------------*/
/*game-over-screen-css-here
/*--------------------------------------------------------------------------------------*/

.game-over-screen-bg {
  background: url(../images/user/11.svg) repeat #000000;
  background-size: cover;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2% 0 5% 0;
}

.game-over-screen-bg:before {
  content: "";
  width: 454px;
  height: 15px;
  background: #FFFB00;
  position: absolute;
  right: 0;
  top: 0;
  animation: moveImage2 0.8s linear infinite;
  animation-duration: 8s;
}

.game-over-screen-bg:after {
  content: "";
  width: 454px;
  height: 15px;
  background: #FFFB00;
  position: absolute;
  left: 0;
  bottom: 0;
  animation: moveImage 0.8s linear infinite;
  animation-duration: 8s;
}

.game-over-screen {
  background: #1d1c1c;
  text-align: center;
  overflow: hidden
}

.game-over-screen-content {
  padding: 25px 20px 0px; 
}

.game-over-screen-content h2 {
  font-size: 45px;
  text-transform: uppercase;
  color: #fff;
  font-family: PFDinMonoMedium
}

.game-over-screen-content p {
  color: rgb(255 255 255 / 70%);
  font-size: 19px;
  font-family: proxima_novalight;
  line-height: 26px
}

.game-over-screen {
  margin-top: 30px
}

.game-over-screen-img img {
  height: 300px;
  width: 100%;
  object-fit: cover
}


/*--------------------------------------------------------------------------------------*/
/*leader-board-screen-css-here
/*--------------------------------------------------------------------------------------*/

.leader-bored {
  width: 100%
}

.leader-bored-bg {
  background: #1d1c1c;
  padding: 50px 70px 75px;
  margin-top: 35px
}

.leader-bored-title {
  font-size: 24px;
  margin: 0;
  color: #fff;
  font-family: PFDinMonoMedium
}

.star-number {
  display: flex;
  align-items: center;
  justify-content: center
}

.star-number span {
  font-size: 57px;
  font-family: PFDinMonoMedium;
  color: #fffb00;
  position: relative;
  top: 5px;
}

.star-number span {
  padding-left: 14px
}

.leader-bored-info {
  display: flex;
  align-items: center;
  justify-content: center
}

.leader-bored-head .leader-bored-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap
}

.leader-bored-head .leader-bored-list {
  width: 60%;
  margin: auto
}

.leader-bored-head {
  /* border-bottom: 1px solid rgb(255 255 255 / 40%); */
  width: 100%;
  margin: auto;
  padding-bottom: 15px;
  margin-bottom: 25px
}

.leader-bored-list li {
  color: rgb(255 255 255 / 70%);
  font-size: 16px;
  font-family: proxima_novalight;
  padding-right: 10px;
  margin-right: 10px;
  position: relative;
  line-height: 24px
}

.leader-bored-list li:last-child:after {
  border-right: 0
}

.leader-bored-list li:after {
  border-right: 1px solid rgb(255 255 255 / 70%);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  content: "";
  height: 12px;
  margin: auto
}

.leader-bored-info li {
  font-size: 18px;
  color: rgb(255 255 255 / 70%);
  font-family: proxima_novaregular;
  padding: 0 20px
}

.leader-bored-info li b {
  font-family: proxima_novasemibold;
  padding-right: 5px
}

.leader-bored-box .leader-bored-info {
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
}

.leader-bored-box .leader-bored-info li {
  padding: 0;
  padding-right: 15px;
  text-wrap: nowrap;
  white-space: nowrap;
}

.leader-bored-box {
  display: flex;
  border: 1px solid #fff;
  padding: 15px 15px;
  align-items: flex-start;
  margin-bottom: 25px
}

.leader-bored-box-L span {
  font-size: 26px;
  color: #fffb00;
  padding-left: 10px;
  line-height: normal
}

.leader-bored-box-L {
  display: flex;
  align-items: center;
  width: 60px
}

.leader-bored-box-L figure {
  margin: 0;
}




.leader-bored-box-R {
  flex: 1;
  text-align: left;
  padding-left: 20px;
  padding-top: 2px
}

.leader-bored-box-R-head {
  display: flex;
  align-items: start;
  justify-content: space-between
}

.leader-bored-box-R-head h3 {
  font-size: 23px;
  color: #fff;
  font-family: PFDinMonoMedium;
  padding-bottom: 8px;
  margin: 0;
}

.leader-bored-box-R-head span {
  font-size: 18px;
  color: #02dc81;
  font-family: proxima_novabold
}

.leader-bored-box-R-head .not-finished {
  color: #e01d62
}

.leader-bored-box .leader-bored-list li {
  font-size: 15px;
  padding-right: 5px;
  margin-right: 5px
}

.leader-bored-box .leader-bored-list li:after {
  height: 12px
}

.leader-bored-box .leader-bored-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

/*--------------------------------------------------------------------------------------*/
/*admin-screen copied button style
/*--------------------------------------------------------------------------------------*/

.activeBttn:active {
  background-color: white;
  box-shadow: 0 2px #666;
  transform: translateY(4px);
}


/*--------------------------------------------------------------------------------------*/
/*admin-screen play icon
/*--------------------------------------------------------------------------------------*/

.videoPlayer {
  display: flex;
  position: relative;
  justify-content: 'center';
  align-items: center
}

.play-icon-admin {
  position: absolute;
  border: 1px solid #1D1C1C;
  border-radius: 45%;
  background-color: #FFF;
  z-index: 1;
}

.admin-video-container {
  position: 'relative';
  display: flex;
  justify-content: center;
  align-items: center;
}


/*--------------------------------------------------------------------------------------*/
/* audio player style
/*--------------------------------------------------------------------------------------*/
/* audio::-webkit-media-controls-panel {
    background-color: #fff;
} */

.audio-player {
  --player-button-width: 30px;
  --sound-button-width: 30px;
  --space: 20px;
  width: 100%;
  height: auto;
  border: 1px solid #FFF;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.controls span {
  color: #FFF;
}

.player-button {
  float: left;
  background-color: transparent;
  border: 0;
  width: var(--player-button-width);
  height: var(--player-button-width);
  cursor: pointer;
  padding: 0;
}

.timeline {
  -webkit-appearance: none;
  width: calc(100% - (var(--player-button-width) + var(--sound-button-width) + var(--space)));
  height: .25em !important;
  background-color: #707070;
  border-radius: 5px;
  background-size: 0% 100%;
  background-image: linear-gradient(#FFFB00, #FFFB00);
  background-repeat: no-repeat;
  margin: 0 var(--space);
}

.timeline::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: all .1s;
  background-color: #FFFB00;
}

.timeline::-moz-range-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: all .1s;
  background-color: #FFFB00;
}

.timeline::-ms-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: all .1s;
  background-color: #FFFB00;
}

.timeline::-webkit-slider-thumb:hover {
  background-color: #FFFB00;
}

.timeline:hover::-webkit-slider-thumb {
  opacity: 1;
}

.timeline::-moz-range-thumb:hover {
  background-color: #FFFB00;
}

.timeline:hover::-moz-range-thumb {
  opacity: 1;
}

.timeline::-ms-thumb:hover {
  background-color: #FFFB00;
}

.timeline:hover::-ms-thumb {
  opacity: 1;
}

.timeline::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.timeline::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.timeline::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.sound-button {
  background-color: transparent;
  border: 0;
  width: var(--sound-button-width);
  height: var(--sound-button-width);
  cursor: pointer;
  padding: 0;
}

/****/


.form-bg-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 9;
  min-height: calc(100vh - 79px);
}

.code-submit-btn img {
  display: none;
}

.container.pt-5 {
  padding-top: 0 !important;
}

.game-over-screen-bg:before,
.game-over-screen-bg:after {
  display: none;
}

.logo.text-center img {
  padding-top: 30px;
}

button.styledBtn svg {
  margin-left: 14px;
}

/* .modal{
  height: unset !important;
  width: unset !important;
}  */


.modal.show {
  display: block !important;
}

.clue-viewer.modal.show {
  display: block !important;
  /* width: max-content; */
  /* height: 100%; */
  background: none;
  min-width: 275px;
  opacity: 0;
  transition: height 300ms;
}

/* .clue-viewer.modal.show.margin-3 {
  margin-left: 33.3%;
} */

.clue-viewer.modal.show.margin-2 {
  margin-left: 0;
  width: 100%;
}


.modal.hide {
  display: none !important;
}

.modal-content {
  border: 0;
}

/* Define css class for global loading message to cover 
   screen during axios operations */


.loading-indicator:before {
  content: '';
  /* background:linear-gradient(45deg, #0A0A0A, #000000, #000A, #1D1C1C); */
  background: #000;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  /* animation: gradient .5s ease infinite; */
}

@keyframes gradient {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.loading-indicator:after {
  content: url(../images/logo.svg);
  position: fixed;
  width: 100%;
  top: 40%;
  left: 0;
  z-index: 1001;
  color: #FFFB00;
  z-index: 99999;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
  font-family: PFDinMonoRegular;

}

.MuiTableRow-root {
  background-color: #FFF;
}


button.styledBtn span {
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.game-side-R.shrink-body {
  padding-right: 230px;
}

iframe {
  position: static !important;
}

.circle-picker {
  padding: 10px 0px 0px 15px;
  width: 230px !important;
  box-shadow: #000000 2px 4px 8px;
}

.circle-picker span div {
  margin-right: 10px !important;
  margin-bottom: 10px !important;
  width: 25px !important;
  height: 25px !important;
}


/* admin additional css */

.MuiTable-root th {
  background: #1d1c1c;
  color: #Fff;
  font-weight: bold;
}

.MuiTableSortLabel-root:hover,
.MuiTableSortLabel-root:focus {
  color: #fff !important;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
  color: #FFF !important;
  opacity: 1;
}

.dlt-icon {
  fill: #c52525 !important;
}

.view-icon {
  fill: #255FF1 !important;
}

.dash-card h3 {
  font-weight: 700;
  font-size: 28px;
}

.chapter-table-header {
  height: auto !important;
  background: #1d1c1c !important;
  display: flex !important;
  justify-content: space-between !important;
  padding: 10px 20px !important;
  border: 1px solid #000 !important;
}

.chapter-table-header h6 {
  margin-bottom: 0;
}

.chapter-table-header h6 b {
  color: #FFF
}

.game-form {
  position: relative;
  left: -15px;
}



.modal-body.need-hint-content p {
  padding: 0px 15px;
}

.hintFooter {
  padding: 15px;
}

.button-aside.close {
  font-weight: normal !important;
  text-shadow: none !important;
}

.width-fix-body.canvas-body {
  max-width: 1000px;
  margin: auto;
}

.canvas-body {
  text-align: center;
}

.canvas-auto {
  background: #1D1C1C;
  display: inline-block;

}

.PrivateNotchedOutline-root-1 {
  border-radius: 0 !important;
}


.MuiSelect-outlined {
  border-radius: 0 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgb(108 104 104) !important;
  border-width: 2px;
}

.MuiButton-containedPrimary {
  color: #000 !important;
  background-color: #fffb3d !important;
  box-shadow: none !important;
}

.MuiButton-containedPrimary:hover {
  background-color: #fffb3d;
  box-shadow: none;
  opacity: 0.8;
}

/* button.btn.btn-primary {
  background: #fffb55;
  border-radius: 0;
} */
.MuiButton-outlined.btn.btn-outline-secondary {
  border-color: #000;
}

.btn-outline-secondary:hover,
.MuiButton-outlined.btn.btn-outline-secondary:hover {
  background: #fffb66;
  color: #000;
  border-color: #fffb66;

}

.MuiButton-text.btn.btn-primary {
  background: #fffb66;

}

.MuiButton-outlined.btn.btn-outline-secondary {
  border-radius: 0;
}


button.btn.btn-outline-secondary,
button.btn.btn-primary {
  border-radius: 0;
}

.date-picker-box input.form-control {
  font-size: 14px;
  border: 0;
  padding: 15px 15px;
  height: 42px;
  font-family: "Open Sans", sans-serif;
}

.date-picker-box input.form-control:focus {
  box-shadow: none;
  outline: 0;
  border-color: transparent;
  height: 42px;
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
  border: 0;

}

.date-picker-box h4 {
  font-size: 16px;
  text-transform: lowercase;
}

fieldset {
  border-radius: 0 !important;
}

.MuiInputBase-input {
  font-size: 14px;
}

.date-picker__clear-btn {
  background-color: #fffb66;
  color: #111 !important;
}

.form-control {
  border-radius: 0 !important;
  background-color: #fff !important;
}

.MuiButtonBase-root,
.MuiButton-root,
.MuiButton-contained,
.MuiButton-containedPrimary {
  border-radius: 0 !important;
}

.MuiInput-underline {
  border-bottom: 1px solid rgb(172, 172, 172) !important;
}

.MuiInput-underline::after,
.MuiInput-underline::before {
  opacity: 0 !important;
}


#multiselectContainerReact .search-wrapper {
  border-bottom: 1px solid rgb(172, 172, 172) !important;
}

.circle-picker {
  background-color: #000;
}

.sidebar-link-list li.active a {

  color: black !important;
  font-family: 'PFDinMonoMedium';
}

.MuiOutlinedInput-input {
  font-size: 14px !important;
}

.MuiOutlinedInput-notchedOutline {
  top: 0 !important
}

.puzzle-image-container {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

.puzzle-image-container img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.need-hint-btn-bg {
  padding: 0px 20px 20px 20px;
}

button.styledBtn.hint-btn {
  background: none;
  border: solid 1px #fffb00;
  color: #fffb00;
  padding: 7px 10px;
  width: 100%;
  text-align: center;
  justify-content: center;
  margin: 0;
}

.delete-drop {
  padding: 8px;
  width: 200px !important;
  box-shadow: #000000 2px 4px 8px;
  background: #000;
  position: absolute;
  right: 0;
  top: 50px;
}

.delete-drop.show {
  display: block !important;
}

.delete-drop ul {
  margin-bottom: 0;
}

.delete-drop ul li {
  color: #fff;
  text-align: left;
  font-size: 15px;
  padding-bottom: 10px;
}

.delete-drop ul li a {
  color: #fff;
  font-family: 'PFDinMonoMedium';
  text-transform: uppercase;
}

.delete-drop li svg {
  fill: #fffb3d;
  font-size: 30px;
}


.hint-text {
  /* transform: rotate(-90deg);
  display: block;
  font-size: 20px;
  color: #000 !important;
  text-align: justify;
  text-justify: inter-word;
  -moz-text-justify: inter-word;
  -webkit-text-justify: inter-word;
  text-transform: uppercase;
  text-size-adjust: auto; */


  transform: rotate(-90deg);
  display: block;
  font-size: 20px;
  color: #000 !important;
  text-align: justify;
  text-justify: inter-word;
  -moz-text-justify: inter-word;
  -webkit-text-justify: inter-word;
  text-transform: uppercase;
  text-size-adjust: auto;
}

span.slider-btn {
  /* background: #fffb3d;
  border-top-left-radius: 42px;
  border-bottom-left-radius: 42px;
  line-height: 175px;
  width: 50px;
  margin: 5px 0;
  display: inline-block;
  height: 175px;
  min-height: 175px;
  padding-top: 9px; */
  
  
  /* display: flex;
  align-items: center;
  justify-content: center; 
  white-space: pre; */


  background: #fffb3d;
  /* margin: 5px 0; */
  display: flex;
  height: 175px;
  width: 50px;
  justify-content: center;
  align-items: center;
  z-index: 888;
  border-radius: 42px 0px 0px 42px;

}

span.hint-btn.slider-btn {
  background: #202124;
}

span.hint-btn.slider-btn .hint-text {
  color: #fffb55 !important;
}

span.genrator-icon {
  position: absolute;
  right: 0;
  top: -12px;
  cursor: pointer;
}

span.genrator-icon img {
  width: 37px !important;
  margin-top: -10px;
}


.video-model-box .modal-content video,
.video-box-screen-1 video {
  max-height: 645px;
}


.generator-box {
  position: relative;
  width: 100%;
}

/* .konvajs-content {
  height: auto !important;
}

.konvajs-content  canvas {
  height: auto !IMPORTANT;
} */


/* body .MuiFormControl-fullWidth{width: auto; flex:1;} */

.game-form {
  min-width: 60%;
  max-width: initial;
  width: 100%;
}

.game-form .MuiFormControl-root {

  width: 45% !important;
}

.hint-text {
  white-space: pre;
}

.width-fix-body .video-box-screen-1 div {
  width: 100% !important;
}


.leader-bored-head.text-center {
  margin-bottom: -10px;
}

.star-number figure {
  margin-bottom: 0;
}

.star-number span {
  line-height: normal;
}

.star-number {
  margin-bottom: 10px;
}

.leader-bored-box-L span {
  position: relative;
  top: -2px;
}


.date-filter-box {
  padding: 30px 0px;
}

.table-head {
  padding: 0px 30px;
}
.clue-viewer.modal img{max-height: 400px; max-width: 100%; width: auto; height: auto;}






.para-leaderboard a{
  color:#02dc81 !important;
  text-decoration: underline !important;
}



.input{
  width: 100%;
  height: 25px !important;
  /* border-bottom: 1px solid #ccc; */
  /* border-radius: 5px; */
  /* padding: 4px 4px 10px; */
  /* font-size: 16px; */
  /* margin-bottom: 10px; 
  position:relative;
  color: #fff;*/
} 

.input::focus{

  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.input::before {
  content: "ENTER YOUR NAME ..."; /* Your placeholder text */
  color: #aaa; /* Placeholder text color */
  position: relative;
  left: 3px; /* Align with padding */
  top: 6px;
  transform: translateY(-50%);
  pointer-events: none; /* Allows clicking through to the div */
}

.input:focus::before {
  display: none; /* Hide placeholder on focus */
}

.input.has-text::before {
    display: none; /* This will hide the placeholder when the div has text */
}