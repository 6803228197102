/* .bd-example-modal-lg .modal-dialog{
    display: table;
    position: relative;
    margin: 0 auto;
    top: calc(50% - 24px);
  }
  
  .bd-example-modal-lg .modal-dialog .modal-content{
    background-color: transparent;
    border: none;
  } */

  
.password__loader{
  
        display: block;
        position: fixed;
        z-index: 600;
        top: 15px;
        right: 15px;
      
}
