.login__page{

    cursor: pointer;
}

.login__page:hover{
color:skyblue;
  
}
/* .navbar-content datta-scroll p:hover{
    color: skyblue;

} */